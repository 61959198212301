// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { BANK_ACCOUNTS_ENDPOINT, BANK_ACCOUNT_CHECKBOOKS_ENDPOINT, BANK_ACCOUNTS_ENDPOINT_ID, UPDATE_BANK_ACCOUNTS_ENDPOINT, UPDATE_BANK_ACCOUNTS_STATUS_ENDPOINT, BANK_ACCOUNT_CHECKBOOK_ENDPOINT } from "../../../../../../utility/SourceAPI"


export const getBankAccounts = createAsyncThunk('appBankAccounts/getBankAccounts', async () => {
    const response = await axios.get(BANK_ACCOUNTS_ENDPOINT)
    return {
        data: response.data
    }
})

export const getAllBankAccounts = createAsyncThunk('appBankAccounts/getAllBankAccounts', async () => {
    const response = await axios.get(BANK_ACCOUNTS_ENDPOINT.concat('?include_all_departments=true'))
    return {
        data: response.data
    }
})


export const getBankAccountById = createAsyncThunk('appBankAccounts/getBankAccounts', async (id) => {
    const response = await axios.get(BANK_ACCOUNTS_ENDPOINT_ID.concat(id))
    return {
        selectedBankAccount: response.data
    }
})


export const getBankAccountCheckbooks = createAsyncThunk('appBankAccounts/getBankAccountCheckbooks', async () => {
    const response = await axios.get(BANK_ACCOUNT_CHECKBOOKS_ENDPOINT)
    return {
        checkbooks: response.data
    }
})

export const getBankAccountCheckBooks = createAsyncThunk('appBankAccounts/getBankAccountCheckBooks', async () => {
    const response = await axios.get(BANK_ACCOUNT_CHECKBOOK_ENDPOINT)
    return {
        checkBooks: response.data
    }
})


export const addBankAccount = createAsyncThunk('appBankAccounts/addBankAccount', async (bankAccount, { dispatch }) => {
    const response = await axios.post(BANK_ACCOUNTS_ENDPOINT, bankAccount)
    await dispatch(getBankAccounts())
    return response.data
})

export const updateBankAccount = createAsyncThunk('appBankAccounts/updateBankAccount', async (bankAccount, { dispatch }) => {
    const response = await axios.put(UPDATE_BANK_ACCOUNTS_ENDPOINT.concat(bankAccount.bankAccountId), bankAccount.bankAccountData)
    await dispatch(getBankAccounts())
    return response.data
})
export const updateBankAccountId = createAsyncThunk('appBankAccounts/updateBankAccountId', async (bankAccount, { dispatch }) => {
    const response = await axios.put(UPDATE_BANK_ACCOUNTS_STATUS_ENDPOINT.concat(`${bankAccount.bankAccountId}/?status_account=${bankAccount.bankAccountData}`))
    await dispatch(getBankAccountById(bankAccount.bankAccountId))
    return response.data
})

export const appBankAccountsSlice = createSlice({
    name: 'appBankAccounts',
    initialState: {
        data: [],
        allAccounts: [],
        selectedBankAccount: {},
        checkbooks: [],
        checkBooks: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getBankAccounts.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.selectedBankAccount = action.payload.selectedBankAccount
        })
        builder.addCase(getBankAccountCheckbooks.fulfilled, (state, action) => {
            state.checkbooks = action.payload.checkbooks
        })
        builder.addCase(getBankAccountCheckBooks.fulfilled, (state, action) => {
            state.checkBooks = action.payload.checkBooks
        })
        builder.addCase(getAllBankAccounts.fulfilled, (state, action) => {
            state.allAccounts = action.payload.data
        })
    }
})

export default appBankAccountsSlice.reducer